import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import modalPreviewData from '../../../../data/previews/modal.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const Modal = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Modal;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSizeModalOpen: false,
      isActionModalOpen: false,
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Modal"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Dialogs" tierThree="Modal" />
        <V5Notice
          componentName="Modal"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#modal"
        />

        <PageNavigation
          links={[
            'Sizes',
            'Content',
            'Buttons',
            'Mobile',
            'Usage',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Sizes">
          <Paragraph>
            Sizes differ only in width, not height. Height is determined by how
            much content appears in the modal.
          </Paragraph>

          <ComponentPreview
            name="ModalSize"
            layout="row"
            previewData={modalPreviewData.sizes}>
            <Button
              buttonType="primary"
              onClick={() => this.setState({ isSizeModalOpen: true })}
              ignoreThisComponent>
              Trigger Modal
            </Button>
            <Modal
              header="Save this playlist?"
              isOpen={this.state.isSizeModalOpen}
              onClose={() => this.setState({ isSizeModalOpen: false })}
              actions={[
                {
                  type: 'primary',
                  text: 'Primary Action',
                  onClick: () => {},
                },
              ]}>
              This is the content area...
            </Modal>
          </ComponentPreview>

          <Paragraph>
            Regardless of the size you choose, the modal should never extend
            beyond the boundaries of the screen.
          </Paragraph>
        </Section>

        <Section title="Content">
          <Paragraph>
            A modal’s content is typically one of two types: plain text or a
            checklist/form field.
          </Paragraph>
          <Paragraph>
            Use <strong>plain text</strong> to confirm an action initiated by
            the user. Their only interaction with the modal will occur in the
            footer (Primary Action, Secondary Action or Cancel).
          </Paragraph>
          <Paragraph>
            The plain text should be clear, complete sentences guiding the user
            toward their preferred course of action. Avoid rhetorical questions.
          </Paragraph>
          <Paragraph>
            Use a <strong>checklist</strong> or <strong>form field</strong> to
            help the user add, remove or customize items. The checklist would
            provide a list of existing options while a form field allows them to
            enter new details.
          </Paragraph>
          <List type="unordered">
            <li>
              Check our{' '}
              <Link href="http://hudl.com/design/writing/writing-for/teams/product#Non-Label%20UI%20Text">
                Microcopy Guidelines
              </Link>{' '}
              to ensure all checklist items have the same format and form field
              placeholders make sense.
            </li>
            <li>
              Anytime a checklist or form field is involved, the primary action
              should be disabled until the user interacts with the content.
            </li>
            <li>
              Regardless of layout, keep the content concise to avoid scrolling.
            </li>
          </List>
        </Section>

        <Section title="Buttons">
          <Paragraph>
            The default size for all modal buttons is medium, though some
            occasions call for small, never large. Each modal can have up to
            three{' '}
            <Link href="/components/buttons/button" isDesignCodeLink>
              buttons
            </Link>
            : primary action, secondary action and cancel.
          </Paragraph>
          <ComponentPreview
            name="ModalAction"
            layout="row"
            previewData={modalPreviewData.actions}>
            <Button
              type="primary"
              onClick={() => this.setState({ isActionModalOpen: true })}
              ignoreThisComponent>
              Trigger Modal
            </Button>
            <Modal
              header="Modal Actions"
              isOpen={this.state.isActionModalOpen}
              onClose={() => this.setState({ isActionModalOpen: false })}
              actions={[
                {
                  buttonType: 'primary',
                  text: 'Primary Action',
                  onClick: () => {},
                },
                {
                  buttonType: 'secondary',
                  text: 'Secondary Action',
                  onClick: () => {},
                },
              ]}>
              <Paragraph>
                The <strong>primary action</strong> is required on every modal.
                Confirm whatever sub-task the user initiated.
              </Paragraph>
              <Paragraph>
                The <strong>secondary action</strong> is optional. Use it to
                cancel the modal (phrased to counteract the primary action) or
                perform a different task altogether.
              </Paragraph>
              <Paragraph>
                The <strong>cancel action</strong> can either replace the
                secondary action or appear alongside the other two buttons.
              </Paragraph>
            </Modal>
          </ComponentPreview>
          <Paragraph>
            Calls-to-action and other button microcopy should reflect what
            appears in the modal title. Be sure the verb explains the purpose of
            the modal. For more info on title-to-CTA congruence, check our{' '}
            <Link href="https://www.hudl.com/design/writing/writing-for/components/cta-buttons-links">
              Microcopy Guidelines
            </Link>
            .
          </Paragraph>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Instead of using modals on mobile, design separate interfaces using{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/">
              modal view
            </Link>{' '}
            if required. The only interjection of this type on mobile should be
            an <Link href="/components/dialogs/alert/design/">alert</Link>,
            following their guidelines. The disruption to the experience should
            be confined to alerts only. Information traditionally accessed
            through a modal can be integrated more elegantly.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>When and Where</SectionSubhead>
          <List type="unordered" className="uni-margin--one--top">
            <li>
              <strong>Always user-initiated.</strong> A modal shouldn’t appear
              without a user taking action, and should always provide context
              explaining what the modal does.
            </li>
            <li>
              <strong>Always dismissible.</strong> Every modal can be dismissed
              by one of 3-4 ways: Dismiss (required), the ESC key, clicking the
              scrim or Cancel (optional).
            </li>
            <li>
              <strong>Always a sub-task.</strong> The modal is a blocking task.
              The user must take action, either in completing the sub-task or
              dismissing the window, to return to the main interface.
            </li>
            <li>
              <strong>Never one of many steps.</strong> The user will open a
              modal with one action in mind. Allow them to complete the single
              sub-task and choose a next step for themselves.
            </li>
            <li>
              <strong>Never displayed in groups.</strong> One modal should never
              open another, and the user should never see more than one at a
              time.
            </li>
          </List>

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Sticking to our{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product">
              Microcopy Guidelines
            </Link>
            , all modal titles should use title case and prioritize keywords.
            Avoid questions as much as possible.
          </Paragraph>
          <DontDo
            dontText="make it so playful the purpose isn’t clear."
            doText="call out the action they’re taking."
            imgFilename="modal-title"
          />
          <DontDo
            dontText="disconnect the title from the CTA."
            doText="make sure it’s congruent to the button copy."
            imgFilename="modal-action"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Dialogs / Modal" />

        <Section title="Platform">
          <PlatformTable
            platforms={{
              apple: (
                <List>
                  <li>
                    Follow{' '}
                    <Link href="https://developer.apple.com/ios/human-interface-guidelines/views/alerts/">
                      Human Interface Guidelines
                    </Link>
                    .
                  </li>
                </List>
              ),
              android: (
                <List>
                  <li>
                    Follow{' '}
                    <Link href="https://material.io/guidelines/components/dialogs.html">
                      Material guidelines
                    </Link>
                    .
                  </li>
                </List>
              ),
            }}
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
